
import { computed, defineComponent, onMounted } from "vue";
import { ElNotification } from "element-plus";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "user-changepassword",
  setup() {
    const store = useStore();
    onMounted(() => {
      setCurrentPageTitle("Нууц үг солих");
    });

    const username = computed(() => {
      return store.getters.username;
    });

    return {
      username
    };
  },
  data() {
    return {
      savebutton: false,
      profileForm: {
        oldpassword: "",
        newpassword: "",
        verifypassword: ""
      },
      profileFormRule: {
        oldpassword: [
          {
            required: true,
            message: "Нууц үг хоосон байна.",
            trigger: "blur"
          },
          {
            min: 8,
            message: "Урт хамгийн багадаа 8-аас их байна.",
            trigger: "blur"
          }
        ],
        newpassword: [
          {
            required: true,
            message: "Нууц үг хоосон байна.",
            trigger: "blur"
          },
          {
            min: 8,
            message: "Урт хамгийн багадаа 8-аас их байна.",
            trigger: "blur"
          }
        ],
        verifypassword: [
          {
            required: true,
            message: "Нууц үг хоосон байна.",
            trigger: "blur"
          },
          {
            min: 8,
            message: "Урт хамгийн багадаа 8-аас их байна.",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    async saveUserPassword() {
      if (this.profileForm.oldpassword.length < 8) return;
      if (this.profileForm.newpassword.length < 8) return;
      if (this.profileForm.verifypassword.length < 8) return;
      if (this.profileForm.newpassword != this.profileForm.verifypassword) {
        ElNotification({
          title: "Алдаа",
          message: "Шинэ нууц үгүүд тохирохгүй байна.",
          type: "error",
          iconClass: "el-text-error"
        });
        return;
      }
      this.savebutton = true;
      await ApiService.post(`userinfo`, {
        params: {
          username: this.username,
          oldpassword: this.profileForm.oldpassword,
          newpassword: this.profileForm.newpassword,
          verifypassword: this.profileForm.verifypassword
        }
      })
        .then(resp => {
          this.savebutton = false;
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.$router.push({
                name: "reference"
              });
            });
          } else if (resp.data.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: resp.data.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.savebutton = false;
          Swal.fire({
            text: "Хэрэглэгчийн мэдээлэл засахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,

            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    }
  }
});
